html, body, #root {
	height:100%;
}

body {
	margin:0;
	--bg1:#3b414a;
	--fg1:#fff;
	--fg2:#fff;
	--bg2:#373a42;
	--darken:rgba(0,0,0,0.25);
	font-family: sans-serif;
	background:var(--bg1);
	color:var(--fg1);
}

a,
button,
input[type='submit'],
input[type='button'] {
	cursor:pointer;
}

a,
button,
input[type='submit'],
input[type='button'],
textarea,
input[type='text'],
input[type='username'],
input[type='password'] {
	padding:1em;
	color:inherit;
	background:inherit;
	border:none;
	font:inherit;
	text-decoration: none;
	&:hover { background:var(--darken); }
	outline:none;
}

input[type='submit'] {
	display: block;
	width:100%;
	border-top:solid 1px var(--bg2);
}